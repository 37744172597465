import React, { useEffect, useState, useMemo } from "react";
import { Link, changeLocale } from "gatsby-plugin-intl";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import MobileMenu from "./mobileMenu";
import { Menu } from "../icons";
import {
  MENU_CATEGORY_ENUM,
  MENU_CATEGORY,
  MENU_ITEMS,
} from "../../contants/routes";
import { useCategoryURL } from "../../hooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    transition: { transition: "all 0.3s ease" },
    root: {
      position: "fixed",
      top: 0,
      zIndex: 2000,
      width: "100%",
      backgroundColor: "transparent",

      "&.isTransformed $menuIcon": {
        color: "#000",
      },

      "&.isTransformed": {
        borderBottom: "0px",
      },
      "&.isTransformed $toolbar": {
        backgroundColor: "#fff",
        color: "#000",
        height: "100%",

        "&:after": {
          content: "''",
          position: "absolute",
          height: "1px",
          left: 0,
          top: "64px",
          backgroundColor: "rgba(233, 233, 233, 0.6)",
          width: "100%",
        },
      },
      "&.isTransformed $link": {
        color: "#4a4a4a",
      },
      "&.isTransformed $languageItem": {
        color: "#4a4a4a",
      },
      "&.isTransformed $languageDivider": {
        backgroundColor: "#4a4a4a",
      },
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: "21px",
      width: "100%",
      minHeight: "64px",
      height: "64px",
      [theme.breakpoints.down("md")]: {
        alignItems: "center",
        paddingTop: 0,
      },

      "&:before": {
        content: "''",
        position: "absolute",
        height: "1px",
        bottom: "0",
        backgroundColor: "rgba(233, 233, 233, 0.2)",
        width: "100%",
      },
      "& li": {
        margin: 0,
      },
    },
    desktopSection: {
      display: "none",

      [theme.breakpoints.up("lg")]: {
        display: "block",
        width: "50%",
      },
    },
    navigation: {
      position: "relative",
      display: "flex",
      margin: 0,
      listStyle: "none",
    },
    navigationGroup: {
      margin: 0,
      "&:last-child > a": {
        marginRight: 0,
      },
    },
    subPageLinkGroupList: {
      listStyleType: "none",
      margin: "30px 0 0 0",
      width: "max-content",
      paddingBottom: "20px",
      display: "none",

      "& > li": {
        marginBottom: "10px",

        "&:last-child": {
          marginBottom: 0,
        },
      },
    },
    isShown: {
      display: "block",
    },
    subPageLink: {
      fontSize: "14px",
      display: "block",
      color: "#858585",
      textDecoration: "none",

      "&:hover": {
        color: "#4a4a4a",
      },
    },
    logoWrapper: {
      display: "flex",
      paddingLeft: "16px",

      [theme.breakpoints.up("xs")]: {
        paddingLeft: "24px",
      },
      [theme.breakpoints.up("sm")]: {
        paddingLeft: "80px",
        width: "25%",
        minWidth: "300px",
      },
    },
    logo: {
      width: "150px",
      margin: 0,
    },
    rightWrapper: {
      display: "flex",
      flexDirection: "row-reverse",
      paddingRight: "16px",

      [theme.breakpoints.up("xs")]: {
        paddingRight: "24px",
      },
      [theme.breakpoints.up("sm")]: {
        paddingRight: "80px",
        width: "25%",
        minWidth: "200px",
      },
    },
    languageWrapper: {
      display: "none",
      maxHeight: "30px",

      [theme.breakpoints.up("lg")]: {
        display: "flex",
        flexDirection: "row-reverse",
      },
    },
    mobileSection: {
      display: "flex",
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    menuIcon: {
      color: "#fff",
    },
    menuIconButton: {
      padding: "5px",
    },
    link: {
      color: "#fff",
      fontSize: "16px",
      fontFamily: ['Poppins', 'system-ui', 'sans-serif'].join(','),
      fontWeight: 700,
      textDecoration: "none",
      position: "relative",
      cursor: "pointer",
      margin: "20px 100px 20px 0px",

      "&:hover": {
        color: "#00dd99 !important",
      },
      "&:after": {
        content: "''",
        position: "absolute",
        height: "4px",
        bottom: "-20px",
        backgroundColor: "#00dd99",
        width: "0%",
        left: 0,
        transition: "all 0.3s ease",
      },
      "&:hover:after": {
        width: "100%",
        left: 0,
      },
    },
    selected: {
      color: "#00dd99 !important",
      "&:after": {
        content: "''",
        position: "absolute",
        height: "4px",
        bottom: "-20px",
        backgroundColor: "#00dd99",
        width: "100%",
        left: "0px",
      },
    },
    languageDivider: {
      margin: "0 20px",
      height: "16px",
      alignSelf: "center",
      opacity: "0.6",
      backgroundColor: "#ffffff",
    },
    languageItem: {
      fontFamily: ['Poppins', 'system-ui', 'sans-serif'].join(','),
      fontWeight: 400,
      fontSize: "12px",
      display: "inline-block",
      cursor: "pointer",
      color: "#fff",
      opacity: "0.6",
    },
    selectedLanguage: {
      opacity: 1,
      fontWeight: 700,
    },
  })
);

type HeaderProps = {
  language: string;
};

function Header({ language }: HeaderProps) {
  const classes = useStyles();
  const [isTop, setTopState] = useState(true);
  const [isTransformed, setTrasformStatus] = useState(false);
  const [isShowSubMenu, setSubmenuStatus] = useState(false);
  const [isMobileMenuOpen, setMobileMenuStatus] = useState(false);
  const intl = useMemo(() => language, [language]);
  const categoryURL = useCategoryURL();

  const handleHeadColorChnage = () => {
    if (window.pageYOffset > 10) {
      setTopState(false);
      setTrasformStatus(true);
      return;
    }
    setTopState(true);
    setTrasformStatus(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleHeadColorChnage);
    return () => {
      window.removeEventListener("scroll", handleHeadColorChnage);
    };
  });

  const handleOpen = () => {
    setTrasformStatus(true);
    setSubmenuStatus(true);
  };

  const handleMouseLeave = () => {
    setTrasformStatus(false);
    setSubmenuStatus(false);
  };

  const handleMobileMenuOpen = () => {
    setMobileMenuStatus(true);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuStatus(false);
  };

  const handleChangeLocale = (locale: string) => {
    changeLocale(locale);
  };

  return (
    <header
      className={clsx(classes.root, classes.transition, {
        isTransformed: isTransformed || !isTop,
      })}
      onMouseEnter={handleOpen}
      onMouseLeave={handleMouseLeave}
    >
      <div className={classes.toolbar}>
        <div className={classes.logoWrapper}>
          <Link to="/">
            <img
              className={classes.logo}
              src={
                isTransformed || !isTop
                  ? "/imgHyperconnect.svg"
                  : "/img-hyperconnect-logo.svg"
              }
              alt="logo"
            />
          </Link>
        </div>
        <nav className={clsx(classes.desktopSection)}>
          <ul className={classes.navigation}>
            {Object.values(MENU_CATEGORY_ENUM).map(category => {
              const subRoute = MENU_ITEMS[category].map(item => {
                if (language === item.exclude && language === "en") {
                  return null;
                }
                if (item.external) {
                  return (
                    <li key={item.label}>
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noreferrer"
                        className={classes.subPageLink}
                      >
                        {item.label}
                      </a>
                    </li>
                  );
                }
                return (
                  <li key={item.label}>
                    <Link to={item.link} className={classes.subPageLink}>
                      {item.label}
                    </Link>
                  </li>
                );
              });
              if (MENU_CATEGORY[category].external) {
                return (
                  <li key={category} className={classes.navigationGroup}>
                    <a
                      href={MENU_CATEGORY[category].link}
                      target="_blank"
                      rel="noreferrer"
                      className={clsx(classes.link, classes.transition, {
                        [classes.selected]: categoryURL === category,
                      })}
                    >
                      {MENU_CATEGORY[category].label}
                    </a>
                    <ul
                      className={clsx(classes.subPageLinkGroupList, {
                        [classes.isShown]: isShowSubMenu,
                      })}
                    >
                      {subRoute}
                    </ul>
                  </li>
                );
              }
              return (
                <li key={category} className={classes.navigationGroup}>
                  <Link
                    to={MENU_CATEGORY[category].link}
                    className={clsx(classes.link, classes.transition, {
                      [classes.selected]: categoryURL === category,
                    })}
                  >
                    {MENU_CATEGORY[category].label}
                  </Link>
                  <ul
                    className={clsx(classes.subPageLinkGroupList, {
                      [classes.isShown]: isShowSubMenu,
                    })}
                  >
                    {subRoute}
                  </ul>
                </li>
              );
            })}
          </ul>
        </nav>
        <div className={classes.rightWrapper}>
          <div className={classes.languageWrapper}>
            <a
              className={clsx(classes.languageItem, {
                [classes.selectedLanguage]: language === "en",
              })}
              onClick={() => handleChangeLocale("en")}
              role="button"
            >
              ENG
            </a>
            <Divider
              orientation="vertical"
              flexItem
              className={classes.languageDivider}
            />
            <a
              className={clsx(classes.languageItem, {
                [classes.selectedLanguage]: language === "ko",
              })}
              onClick={() => handleChangeLocale("ko")}
              role="button"
            >
              KOR
            </a>
          </div>
          <div className={clsx(classes.mobileSection)}>
            <IconButton
              aria-label="show more"
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
              className={classes.menuIconButton}
            >
              <Menu className={classes.menuIcon} />
            </IconButton>
          </div>
        </div>
      </div>
      {/* mobile menu */}
      {isMobileMenuOpen && (
        <MobileMenu onClose={handleMobileMenuClose} language={intl} />
      )}
    </header>
  );
}

export default Header;
