import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

function Home (props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        d="M12.2 3.6c-.233 0-.456.093-.62.26l-8.803 7.68c-.112.082-.177.212-.177.35 0 .242.195.437.436.437h2.182v6.982c0 .482.391.873.873.873h3.49c.483 0 .874-.391.874-.873v-5.236h3.49v5.236c0 .482.391.873.873.873h3.491c.482 0 .873-.391.873-.873v-6.982h2.182c.24 0 .436-.195.436-.436 0-.139-.065-.269-.177-.35l-8.797-7.677-.005-.005c-.165-.166-.388-.259-.621-.259"
      />
    </SvgIcon>
  );
};

export default Home;
