export enum MENU_CATEGORY_ENUM {
  HYPERCONNECT = "company",
  PRODUCT = "business",
  TECH = "tech",
  CAREER = "career",
  CONTACT = "contact",
};

export type MenuItemsType = {
  label: string;
  link: string;
  exclude?: string;
  external?: boolean;
};

type MenuCategoryType = {
  [value in MENU_CATEGORY_ENUM]: {
    label: string;
    link: string;
    external?: boolean;
  }
};

export const MENU_CATEGORY: MenuCategoryType = {
  [MENU_CATEGORY_ENUM.HYPERCONNECT]: {
    label: "About",
    link: "/",
  },
  [MENU_CATEGORY_ENUM.PRODUCT]: {
    label: "Product",
    link: "/business",
  },
  [MENU_CATEGORY_ENUM.TECH]: {
    label: "Tech",
    link: "/tech",
  },
  [MENU_CATEGORY_ENUM.CAREER]: {
    label: "Career",
    link: "https://career.hyperconnect.com/",
    external: true,
  },
  [MENU_CATEGORY_ENUM.CONTACT]: {
    label: "Contact",
    link: "/contact",
  },
};
export const MENU_ITEMS: {
  [key in MENU_CATEGORY_ENUM]: MenuItemsType[]
} = {
  [MENU_CATEGORY_ENUM.HYPERCONNECT]: [
    { label: "Our History", link: "/company/milestone" },
    { label: "News", link: "/company/news", exclude: "en" },
    { label: "Intellectual property", link: "/company/intellectualProperty", },
    { label: "Code of Ethics", link: "/company/CodeOfEthics", },
  ],
  [MENU_CATEGORY_ENUM.PRODUCT]: [
    { label: "Azar", link: "/business#azar" },
    { label: "Hakuna Live", link: "/business#hakuna" },
    { label: "Enterprise", link: "/business#enterprise" },
    { label: "Hyper-X", link: "/business#hyper-x" },
  ],
  [MENU_CATEGORY_ENUM.TECH]: [
    { label: "WebRTC", link: "/tech/webrtc" },
    { label: "AI", link: "/tech/aiml" },
    { label: "AR", link: "/tech/ar" },
    { label: "Data", link: "/tech/data" },
    {
      label: "Tech Blog",
      link: "https://hyperconnect.github.io/",
      external: true,
    },
  ],
  [MENU_CATEGORY_ENUM.CAREER]: [],
  [MENU_CATEGORY_ENUM.CONTACT]: [],
};