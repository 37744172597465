/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import "typeface-noto-sans";

import Header from "./header";
import Footer from "./footer";
import { Helmet } from "react-helmet";

import "@fontsource/inter";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/700.css";
import "./layout.css";

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "Inter",
      "Pretendard",
      "system-ui",
      "sans-serif",
    ].join(","),
    h1: {
      fontFamily: ['Poppins', 'Pretendard', 'system-ui', 'sans-serif'].join(','),
    },
    h2: {
      fontFamily: ['Poppins', 'Pretendard', 'system-ui', 'sans-serif'].join(','),
    },
    h3: {
      fontFamily: ['Poppins', 'Pretendard', 'system-ui', 'sans-serif'].join(','),
    },
    h4: {
      fontFamily: ['Poppins', 'Pretendard', 'system-ui', 'sans-serif'].join(','),
    },
    h5: {
      fontFamily: ['Poppins', 'Pretendard', 'system-ui', 'sans-serif'].join(','),
    },
    h6: {
      fontFamily: ['Poppins', 'Pretendard', 'system-ui', 'sans-serif'].join(','),
    },
  },
});

interface LayoutProps {
  language: string;
  children: React.ReactNode;
}

function Layout (props: LayoutProps) {
  useEffect(()=> {
    import('scrollreveal').then(({ default: ScrollReveal }) => {
      const sr = ScrollReveal()
      sr.reveal('.reveal', {
        delay: 500,
        distance: '150%',
        origin: 'bottom',
      });
    });
  }, [])
 
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        {/* <!-- OneTrust Cookies Consent Notice start for hyperconnect.com --> */}
        <script type="text/javascript" src="https://cdn.cookielaw.org/consent/855c5baf-4c2c-4590-8da2-b3d9d61013eb/OtAutoBlock.js"></script>
        <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" data-document-language="true" type="text/javascript" charSet="UTF-8" data-domain-script="855c5baf-4c2c-4590-8da2-b3d9d61013eb"></script>
        <script type="text/javascript">{`function OptanonWrapper() {}`}</script>
        {/* <!-- OneTrust Cookies Consent Notice end for hyperconnect.com --> */}
      </Helmet>
      <Header language={props.language} />
      <div>{props.children}</div>
      <Footer language={props.language} />
    </ThemeProvider>
  );
}

export default Layout;
