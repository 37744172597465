import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

function AppStore (props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        d="M14.8 9.007l3.014 4.821h1.472c.615 0 1.114.5 1.114 1.115s-.5 1.114-1.114 1.114h-.08l1.025 1.638c.325.521.167 1.209-.355 1.535-.184.115-.387.17-.59.17-.37 0-.733-.186-.945-.524l-5.037-8.058c.09-.222.204-.46.375-.732.308-.492.704-.834 1.122-1.079zm-8.885 8.164c.673 0 1.231.213 1.7.496l-.755 1.209c-.212.338-.574.524-.946.524-.202 0-.406-.055-.59-.17-.522-.326-.68-1.014-.354-1.535l.3-.481c.193-.025.402-.043.645-.043zM14.862 3.97c.523.326.681 1.013.354 1.535l-5.202 8.323h3.551c.284.204.599.52.93 1.049.247.391.367.79.434 1.18H5.915c-.615 0-1.115-.5-1.115-1.114 0-.615.5-1.115 1.115-1.115h1.472l3.9-6.24-1.303-2.083c-.325-.522-.167-1.209.354-1.535.522-.326 1.21-.167 1.536.354l.726 1.162.727-1.162c.326-.523 1.014-.682 1.535-.354z"
      />
    </SvgIcon>
  );
};

export default AppStore;
