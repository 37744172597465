import IconButton, { IconButtonTypeMap } from "@material-ui/core/IconButton";
import { Link, changeLocale } from "gatsby-plugin-intl";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { Close } from "../../components/icons";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import React from "react";
import clsx from "clsx";

const drawerWidth = "100%";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    // necessary for content to be below app bar
    toolbar: {
      minHeight: "64px",
      flexDirection: "row",
      justifyContent: "space-between",
      display: "flex",
      alignItems: "center",

      "& img": {
        margin: 0,
      },
      padding: "0 16px",

      [theme.breakpoints.up("xs")]: {
        padding: "0 24px",
      },
      [theme.breakpoints.up("sm")]: {
        padding: "0 80px",
      },
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
    drawContainer: {
      padding: "0 16px",
      [theme.breakpoints.up("xs")]: {
        padding: "0 24px",
      },
      [theme.breakpoints.up("sm")]: {
        padding: "0 80px",
      },
    },
    menuSectionHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      color: "#4a4a4a",
      fontFamily: ['Poppins', 'system-ui', 'sans-serif'].join(','),
      fontSize: "24px",
      fontWeight: 700,
      textDecoration: "none",
      padding: "20px 0",
    },
    subPageLink: {
      display: "block",
      fontSize: "18px",
      color: "#9c9c9c",
      textDecoration: "none",
      marginBottom: "10px",

      "&:last-child": {
        marginBottom: "20px",
      },
    },
    languageWrapper: {
      display: "flex",
      margin: "20px 0",
    },
    languageDiver: {
      margin: "0 20px",
      height: "20px",
    },
    languageLink: {
      color: "#4a4a4a",
      fontFamily: ['Poppins', 'system-ui', 'sans-serif'].join(','),
      fontSize: "14px",
      fontWeight: 400,
      cursor: "pointer",
      opacity: "0.6",
    },
    logo: {
      width: "150px",
    },
    selectedLanguage: {
      opacity: 1,
      fontWeight: 700,
    },
    closeIcon: {
      padding: "4px",
      "&:hover": {
        backgroundColor: "#fff",
      },
    },
  })
);
interface MobileMenuProps {
  onClose:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  language: string;
}
export default function MobileMenu({ onClose, language }: MobileMenuProps) {
  const classes = useStyles();

  const handleChangeLocale = (locale: string) => {
    changeLocale(locale);
  };

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
      >
        <div className={classes.toolbar}>
          <img
            className={classes.logo}
            src={"/imgHyperconnect.svg"}
            alt="logo"
          />
          <IconButton onClick={onClose} className={classes.closeIcon}>
            <Close />
          </IconButton>
        </div>
        <Divider />
        <div className={classes.drawContainer}>
          <div>
            <Link to="/" className={classes.menuSectionHeader}>
              About
            </Link>
            <div>
              <Link to="/company/milestone" className={classes.subPageLink}>
                Our History
              </Link>
              {language !== "en" && (
                <Link to="/company/news" className={classes.subPageLink}>
                  News
                </Link>
              )}
              <Link
                to="/company/intellectualProperty"
                className={classes.subPageLink}
              >
                Intellectual property
              </Link>
              <Link to="/company/CodeOfEthics" className={classes.subPageLink}>
                Code of Ethics
              </Link>
            </div>
          </div>

          <div>
            <Link to="/business" className={classes.menuSectionHeader}>
              Product
            </Link>
            <div>
              <Link to="/business#azar" className={classes.subPageLink}>
                Azar
              </Link>
              <Link to="/business#hakuna" className={classes.subPageLink}>
                Hakuna Live
              </Link>
              <Link to="/business#hyper-x" className={classes.subPageLink}>
                Hyper-X
              </Link>
            </div>
          </div>

          <div>
            <a
              href="https://hce.io/"
              target="_blank"
              rel="noreferrer"
              className={classes.menuSectionHeader}
            >
              Enterprise
            </a>
          </div>

          <div>
            <Link to="/tech/" className={classes.menuSectionHeader}>
              Tech
            </Link>
            <div>
              <Link to="/tech/webrtc" className={classes.subPageLink}>
                WebRTC
              </Link>
              <Link to="/tech/aiml" className={classes.subPageLink}>
                AI
              </Link>
              <Link to="/tech/ar" className={classes.subPageLink}>
                AR
              </Link>
              <Link to="/tech/data" className={classes.subPageLink}>
                Data
              </Link>
              <a
                href="https://hyperconnect.github.io/"
                target="_blank"
                rel="noreferrer"
                className={classes.subPageLink}
              >
                Tech Blog
              </a>
            </div>
          </div>

          <div>
            <a
              href="https://career.hyperconnect.com/"
              target="_blank"
              rel="noreferrer"
              className={classes.menuSectionHeader}
            >
              Career
            </a>
          </div>

          <div>
            <Link to="/contact" className={classes.menuSectionHeader}>
              Contact
            </Link>
          </div>
          <div className={classes.languageWrapper}>
            <a
              className={clsx(classes.languageLink, {
                [classes.selectedLanguage]: language === "en",
              })}
              onClick={() => handleChangeLocale("en")}
              role="button"
            >
              ENG
            </a>
            <Divider
              orientation="vertical"
              flexItem
              className={classes.languageDiver}
            />
            <a
              className={clsx(classes.languageLink, {
                [classes.selectedLanguage]: language === "ko",
              })}
              onClick={() => handleChangeLocale("ko")}
              role="button"
            >
              KOR
            </a>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
